<template>
  <div v-if="appointments">
    <Calendar :appointments="appointments" />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Calendar from '../../components/Calendar.vue';
import { FilterMatchMode } from 'primevue/api';

export default {
  name: 'AppointmentsCalendar',
  components: { Calendar },
  props: {},
  data() {
    return {
      defaultFilters: {
        appointmentApprovedState: { value: null, matchMode: FilterMatchMode.EQUALS },
      },

      defaultCustomFilters: {
        from: {
          value: this.$dayjs()
            .startOf('week')
            .toDate(),
          matchMode: FilterMatchMode.EQUALS,
        },
        to: {
          value: this.$dayjs()
            .startOf('week')
            .add(6, 'days')
            .toDate(),
          matchMode: FilterMatchMode.EQUALS,
        },
        appointmentStates: [],
        appointmentTypes: [],
      },

      tableState: {
        filterName: 'appointments-calendar-filters',
        customFilterName: 'appointments-custom-calendar-filters',
        filters: this.defaultFilters,
        customFilters: this.defaultCustomFilters,
      },
    };
  },
  computed: {
    ...mapGetters(['getAppointments', 'getEnumValues']),
    appointments() {
      return this.getAppointments;
    },
  },
  methods: {
    ...mapActions(['fetchEnumValues', 'fetchAppointments']),

    /**
     * Load remote table data
     */
    async loadAppointmentData() {
      await this.fetchAppointments({
        filters: { ...this.tableState.filters, ...this.tableState.customFilters },
      });
      // console.log('loadAppointmentData', this.getAppointments);
    },
  },
  async mounted() {
    await this.fetchEnumValues('AppointmentType');
    await this.fetchEnumValues('AppointmentState');
    await this.loadAppointmentData();
  },
};
</script>
