<template>
  <FullCalendar v-if="appointments" :options="calendarOptions"> </FullCalendar>
</template>

<script>
import FullCalendar from '@fullcalendar/vue';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import de from '@fullcalendar/core/locales/de';

export default {
  components: {
    FullCalendar, // make the <FullCalendar> tag available
  },
  props: {
    appointments: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      calendarOptions: {
        eventTimeFormat: {
          hour: '2-digit',
          minute: '2-digit',
        },
        locale: de,
        plugins: [dayGridPlugin, interactionPlugin],
        initialView: 'dayGridMonth',
        selectable: true,
        events: this.appointments,
      },
    };
  },

  async mounted() {
    this.calendarOptions.events = this.appointments;
  },

  watch: {
    appointments(newValue, oldValue) {
      this.calendarOptions.events = newValue;
    },
  },
};
</script>
